import theGodOfFortuneProvider from "./the-god-of-fortune"
import treasureHunt from "./treasure-hunt"

// 游戏icon配置
// ----------------------------------------------------------------------
const photoProvider = new Map()

// 财神到
photoProvider.set(13, theGodOfFortuneProvider)

// 连环夺宝
photoProvider.set(22, treasureHunt)

export default photoProvider
