import classNames from "utils/class-names"

const Id = ({ isBot, value }) => {
  return (
    <span
      className={classNames("font-bold", {
        "text-red underline": !isBot
      })}
    >
      {value}
    </span>
  )
}

export default Id
