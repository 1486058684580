// assets
import drill from "assets/treasure-hunt/gem-0.png"
import gem1 from "assets/treasure-hunt/gem-1.png"
import gem2 from "assets/treasure-hunt/gem-2.png"
import gem3 from "assets/treasure-hunt/gem-3.png"
import gem4 from "assets/treasure-hunt/gem-4.png"
import gem5 from "assets/treasure-hunt/gem-5.png"
import gem6 from "assets/treasure-hunt/gem-6.png"
import gem7 from "assets/treasure-hunt/gem-7.png"
import gem8 from "assets/treasure-hunt/gem-8.png"
import gem9 from "assets/treasure-hunt/gem-9.png"
import gem10 from "assets/treasure-hunt/gem-10.png"
import gem11 from "assets/treasure-hunt/gem-11.png"
import gem12 from "assets/treasure-hunt/gem-12.png"
import gem13 from "assets/treasure-hunt/gem-13.png"
import gem14 from "assets/treasure-hunt/gem-14.png"
import gem15 from "assets/treasure-hunt/gem-15.png"

import { Icon } from "../helper"

const imageProvider = new Map()

imageProvider.set(0, new Icon(() => "drill", drill))
imageProvider.set(1, new Icon(() => "gem-01", gem1))
imageProvider.set(2, new Icon(() => "gem-02", gem2))
imageProvider.set(3, new Icon(() => "gem-03", gem3))
imageProvider.set(4, new Icon(() => "gem-04", gem4))
imageProvider.set(5, new Icon(() => "gem-05", gem5))
imageProvider.set(6, new Icon(() => "gem-06", gem6))
imageProvider.set(7, new Icon(() => "gem-07", gem7))
imageProvider.set(8, new Icon(() => "gem-08", gem8))
imageProvider.set(9, new Icon(() => "gem-09", gem9))
imageProvider.set(10, new Icon(() => "gem-10", gem10))
imageProvider.set(11, new Icon(() => "gem-11", gem11))
imageProvider.set(12, new Icon(() => "gem-12", gem12))
imageProvider.set(13, new Icon(() => "gem-13", gem13))
imageProvider.set(14, new Icon(() => "gem-14", gem14))
imageProvider.set(15, new Icon(() => "gem-15", gem15))

export default imageProvider
