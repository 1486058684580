import Decimal from "decimal.js"
import { isBoolean, isFinite } from "lodash"
import numeral from "numeral"

const DEFAULT_VALUE = 0

/**
 * @description 可以转换成数字型别时转换成数字型别并回传一个数字，若
 * 1.无法解析转换
 * 2.Infinity
 * 则回传数字 0
 * @param {any} value
 * @returns {number}
 */
export function checkNumber(value) {
  if (isBoolean(value)) {
    return DEFAULT_VALUE
  }

  const targetNum = Number(value)
  return isFinite(targetNum) ? targetNum : DEFAULT_VALUE
}
// ------------------(以上为底层功能函式)-----------------------------------------

/**
 *
 * @description 格式化 - 一般格式化
 * 1234 => 1,234
 * 1234.56 => 1,234.56
 * @param {string} value
 * @return {string}
 */
export function convertNormal(value) {
  const decimal = new Decimal(checkNumber(value))

  if (decimal.isInteger()) {
    return numeral(decimal.toString()).format("0,0")
  }

  return numeral(decimal.toFixed(2)).format("0,0.00")
}
