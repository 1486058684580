import { Col, Row } from "antd"

import Money from "../Money"
import Picture from "../Picture"
import Text from "../Text"
import Id from "../id"

// main
const SheetTwo = ({ list, gameType }) => {
  return (
    <Row gutter={[0, 3]}>
      {list.map((item, detailIndex) => {
        const spans = Array.isArray(item.spans) ? item.spans : []
        return (
          <Col key={detailIndex} span={24} className="space-x-1.5">
            {spans.map((content, index) => {
              switch (content.type) {
                case "id":
                  return <Id key={index} isBot={content.bot} value={content.text} />
                case "win":
                case "score":
                  return <Money key={index} value={content.text} />
                case "icon":
                  return <Picture key={index} imageId={content.text} gameType={gameType} />
                default:
                  return <Text key={index} value={content.text} />
              }
            })}
          </Col>
        )
      })}
    </Row>
  )
}

export default SheetTwo
