import "antd/dist/antd.min.css"
import { Contents } from "./components"

function App() {
  return (
    <div className="App">
      <div className="p-5">
        <Contents />
      </div>
    </div>
  )
}

export default App
