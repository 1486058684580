import bun from "assets/the-god-of-fortune/bun.png"
import candied_haws from "assets/the-god-of-fortune/candied_haws.png"
import earth_god from "assets/the-god-of-fortune/earth_god.png"
import free_games from "assets/the-god-of-fortune/free_games.png"
import god_of_wealth from "assets/the-god-of-fortune/god_of_wealth.png"
import goldfish from "assets/the-god-of-fortune/goldfish.png"
import lucky_bag from "assets/the-god-of-fortune/lucky_bag.png"
import maitreya from "assets/the-god-of-fortune/maitreya.png"
import peach from "assets/the-god-of-fortune/peach.png"
// assets
import versatile from "assets/the-god-of-fortune/versatile.png"
import wishes from "assets/the-god-of-fortune/wishes.png"

import { Icon } from "../helper"

const imageProvider = new Map()

imageProvider.set(0, new Icon(() => "百搭", versatile))
imageProvider.set(1, new Icon(() => "糖葫芦", candied_haws))
imageProvider.set(2, new Icon(() => "包子", bun))
imageProvider.set(3, new Icon(() => "桃子", peach))
imageProvider.set(4, new Icon(() => "如意", wishes))
imageProvider.set(5, new Icon(() => "幸运袋", lucky_bag))
imageProvider.set(6, new Icon(() => "金鱼", goldfish))
imageProvider.set(7, new Icon(() => "土地公", earth_god))
imageProvider.set(8, new Icon(() => "弥勒佛", maitreya))
imageProvider.set(9, new Icon(() => "财神", god_of_wealth))
imageProvider.set(10, new Icon(() => "免费游戏", free_games))

export default imageProvider
