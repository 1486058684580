import imageProvider from "components/GameSheet/config"

// main
const Picture = ({ imageId, gameType }) => {
  const currentGame = imageProvider.get(gameType) || new Map()
  const icon = currentGame.get(Number(imageId)) || {}

  return (
    <span className="inline-block h-6 w-6 align-top">
      {icon.img ? <img src={icon.img} alt={icon.label || "Game Icon"} className="h-full w-full object-contain object-center" /> : null}
    </span>
  )
}

export default Picture
