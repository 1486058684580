export class Icon {
  constructor(i18nFn, imageInstance) {
    this.i18nFn = i18nFn
    this.imageInstance = imageInstance
  }

  get label() {
    return this.i18nFn()
  }

  get img() {
    return this.imageInstance
  }
}

/**
 * 驗證是否有詳情
 */
export function hasDetails(details) {
  return Array.isArray(details) && details.length > 0
}
