import { memo } from "react"

import { COLUMN_EMPTY_TEXT } from "utils/antd/table"

import SheetOne from "./components/SheetOne"
import SheetTwo from "./components/SheetTwo"
import { hasDetails } from "./helper"

// main
const GameSheet = ({ record = {} }) => {
  const { gameDetail = {} } = record
  const { details, details2, userId } = gameDetail

  /**
   * 有接口2(新版json格式)优先用
   */
  if (hasDetails(details2)) {
    return (
      <div className="max-w-[630px]">
        <SheetTwo list={details2} gameType={record.gameType} />
      </div>
    )
  }

  if (hasDetails(details)) {
    return (
      <div className="max-w-[630px]">
        <SheetOne list={details} userId={userId} />
      </div>
    )
  }

  return COLUMN_EMPTY_TEXT
}

export default memo(GameSheet)
