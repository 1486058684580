import React, { useState } from "react"
import { Descriptions, Space, Tag } from "antd"
import axios from "axios"
import dayjs from "dayjs"
import { get } from "lodash"
import { useSearchParam, useMount } from "react-use"
import { formatMoney } from "utils"
import { COLUMN_EMPTY_TEXT } from "utils/antd/table"
import classNames from "utils/class-names"
import GameSheet from "./GameSheet"

const DescriptionItem = Descriptions.Item

const ITEM_LIST = [
  { label: "投注单号", key: "settleId" },
  { label: "ServerID", key: "serverId" },
  { label: "游戏", key: "gameDetail.gameName" },
  { label: "房间 / 台号", key: "roomName" },
  {
    label: "总输赢",
    key: "winScore",
    render: winScore => {
      const isWin = winScore > 0
      const isLose = winScore < 0

      return (
        <>
          <span
            className={classNames("mr-1", {
              "text-red": isWin,
              "text-green": isLose
            })}
          >
            {formatMoney(winScore)}
          </span>
          元
        </>
      )
    }
  },
  {
    label: "余额",
    key: "latestScore",
    render: latestScore => `${formatMoney(latestScore)} 元`
  },
  {
    label: "结算时间",
    key: "updateTime",
    render: updateTime => (updateTime ? `${dayjs(updateTime).format("YYYY-MM-DD HH:mm:ss")} (UTC+8)` : COLUMN_EMPTY_TEXT)
  },
  {
    label: "备注",
    key: "gameDetail.tags",
    render: tags => {
      const hasTags = Array.isArray(tags) && tags.length > 0

      if (hasTags) {
        return (
          <Space size="small" wrap>
            {tags.map((item, index) => (
              <Tag color="blue" key={index}>
                {item}
              </Tag>
            ))}
          </Space>
        )
      }

      return COLUMN_EMPTY_TEXT
    }
  },
  {
    label: "record_ID",
    key: "unionId",
    render: unionId => unionId ?? COLUMN_EMPTY_TEXT,
    span: 2
  },
  {
    label: "玩家信息",
    key: "gameDetail.userIds",
    render: (userIds, data) => {
      if (!Array.isArray(userIds)) {
        return COLUMN_EMPTY_TEXT
      }

      const realUserId = data.userId

      if (userIds.length > 0) {
        return (
          <>
            {userIds.map((userId, index) => {
              const isRealGamer = userId === realUserId

              return (
                <span key={index} className={classNames("mr-[6px] whitespace-nowrap", { "text-red": isRealGamer })}>
                  {isRealGamer ? data.agentUserId : userId}
                </span>
              )
            })}
            <div className="text-gray">(红色标注的帐号表示此注单为该名玩家所产生)</div>
          </>
        )
      }

      return (
        <>
          <span className="text-red">{realUserId}</span>
          <div className="text-gray">(红色标注的帐号表示此注单为该名玩家所产生)</div>
        </>
      )
    },
    span: 2
  },
  {
    label: "详情",
    key: "gameDetail",
    render: (_, record) => {
      return <GameSheet record={record} />
    },
    span: 2
  }
]

const replaceSpaceToEncodedPlusSign = param => param.replaceAll(" ", "%2B")

export default function Contents() {
  const [betDetail, setBetDetail] = useState({})
  const [mounted, setMounted] = useState(false)
  const p = useSearchParam("p")
  const s = useSearchParam("s")

  useMount(() => {
    axios
      .get(`/api/detail?p=${replaceSpaceToEncodedPlusSign(p)}&s=${replaceSpaceToEncodedPlusSign(s)}`)
      .then(res => {
        if (res?.data?.code === 0) {
          res.data?.data && setBetDetail(res.data.data)
        } else {
          alert("发生错误")
        }
      })
      .catch(e => {
        console.log(e)
        alert("发生错误")
      })
      .then(() => {
        setMounted(true)
      })
  })

  if (!mounted) return null

  return (
    <Descriptions column={{ xxl: 2, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 }} bordered labelStyle={{ width: 140 }}>
      {ITEM_LIST.map((item, index) => (
        <DescriptionItem key={index} label={item.label} span={item?.span || 1}>
          {get(betDetail, item.key) !== undefined ? (item.render ? item.render(get(betDetail, item.key), betDetail) : get(betDetail, item.key)) : null}
        </DescriptionItem>
      ))}
    </Descriptions>
  )
}
