import { red } from "@ant-design/colors"

import parse from "html-react-parser"
import { replace } from "lodash"

// main
const SheetOne = ({ list = [], userId = null }) => {
  // 旧的接口资料就保持一样处理方式
  return list.map((item, index) => {
    const content = replace(item, `]${userId}&nbsp;`, `]<span style="color: ${red.primary}">${userId}</span>&nbsp;`)
    return <div key={index}>{parse(content)}</div>
  })
}

export default SheetOne
